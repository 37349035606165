@use '../../../styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,500;1,100&family=Roboto:wght@100&display=swap');

.RatingHeading {
  font-family: 'Raleway', sans-serif;
  color: colors.$dark_color;
  text-align: center;
}

.bookTitle {
  font-family: 'Raleway', sans-serif;
  color: darkslategrey;
  font-size: larger;
  border-bottom: solid 2px colors.$dark_color;
  text-align: center;
}

.switchField {
  float: right;
  margin: 1%;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.amplify-stepperfield__input {
  border-color: colors.$dark_color;
  border-top: solid 1px colors.$dark_color;
  color: colors.$dark_color;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: large;
}

.amplify-stepperfield__button--increase {
  border-radius: 8px;
  color: colors.$dark_color;
  border-color: colors.$dark_color;
  border-width: 1px;
}

.amplify-stepperfield__button--decrease {
  border-radius: 8px;
  background-color: colors.$primary_color1;
  color: antiquewhite;
  border-color: colors.$dark_color;
  border-width: 1px;
}

.cancelButton {
  font-family: 'Roboto', sans-serif;
  font-size: medium;
}

.submitButton {
  font-family: 'Roboto', sans-serif;
  font-size: medium;
  float: right;
}

.deleteButton {
  font-family: 'Roboto', sans-serif;
  font-size: large;
  margin-top: 2%;
}
