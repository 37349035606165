
@use '../../../styles/colors';

$color_1: colors.$background_color;
$color_2: #fff;
$color_3: colors.$background_color;
$background-color_1: colors.$dark_color;
$accent_color: colors.$primary_color1;


.navbar {
  background-color: $background-color_1;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 74px;
  max-width: 1500px;
  position: relative;
}
.nav-logo {
  color: $color_1;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  display: flex;
  justify-items: center;
  .icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-left: 16px;
  }
}
.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}
.nav-links {
  color: $color_2;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.nav-item {

  line-height: 40px;
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  &:hover {
    &:after {
      width: 100%;
      background: $accent_color;
    }
  }
  .active {
    color: $color_3;
    border: 1px solid $accent_color;
  }
}
.nav-icon {
  display: none;
  color: $color_1;
  .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
}
@media screen and (max-width: 960px) {

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 74px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: $background-color_1;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item {
    .active {
      color: $color_3;
      border: none;
    }
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-logo {
    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: $color_3;
  }
}
