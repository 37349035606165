.RatingsButton{
  font-family: 'Roboto', sans-serif;
  font-size: large;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 20%;
  text-decoration: none;
}

.UpdateButton {
  font-family: 'Roboto', sans-serif;
  font-size: large;
  margin-top: 2%;
  float: right;
}
